import type { loader as rootLoader } from '~/root.tsx';

import { useRouteLoaderData } from 'react-router';

export type RootLoaderData = Awaited<ReturnType<typeof rootLoader>>;

export function useRootLoaderData() {
  const data = useRouteLoaderData(`root`) as RootLoaderData;

  return data;
}
